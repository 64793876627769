import { request } from "@/request/request";
import { parameterChange } from "@/utils/utils";

// 文件上传
export default {
  uploadImage(data) {
    return request({
      url: "/file/uploadImage",
      method: "post",
      data: data,
    });
  },
  // 获取轮播图
  getBannerList(data) {
    return request({
      url: "/banner/getBannerList?" + parameterChange(data),
      method: "get",
      data: data,
    });
  },

  // 活动报名列表
  getEnrollList(data) {
    return request({
      url: "/enroll/getEnrollList",
      method: "get",
      data: data,
    });
  },

  // 收藏列表
  getCollectList(data) {
    return request({
      url: "/course_collect/getCollectList",
      method: "get",
      data: data,
    });
  },

  // 课类下拉列表
  classifyList(data) {
    return request({
      url: "/classify/classifyList?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 标签下拉列表
  tagList(data) {
    return request({
      url: "/tag/tagList?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 首页列表
  getCourseList(data) {
    return request({
      url: "/homepage/getCourseList?" + parameterChange(data),
      method: "get",
      data: data,
    });
  },
  // 课程搜索
  search(data) {
    return request({
      url: "/search/search",
      method: "post",
      data: data,
    });
  },
  // 热门课程
  getHotCourse(data) {
    return request({
      url: "/play_history/getHotCourse",
      method: "get",
      data: data,
    });
  },
};
