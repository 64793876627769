import BaseLayout from "@/layout/BaseLayout";

export default [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "index",
    redirect: "/home",
    component: BaseLayout,
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/home/index.vue"),
      },
      {
        path: "/search",
        name: "search",
        component: () => import("@/views/home/search.vue"),
      },
      {
        path: "/offlineClass",
        name: "offlineClass",
        redirect: "/offlineClass/list",
        children: [
          {
            path: "/offlineClass/list",
            name: "offlineClass",
            component: () => import("@/views/offlineClass/index.vue"),
          },
          {
            path: "/offlineClass/details",
            name: "offlineDetails",
            component: () => import("@/views/offlineClass/details.vue"),
          },
          {
            path: "/offlineClass/enroll",
            name: "offlineEnroll",
            component: () => import("@/views/offlineClass/enroll.vue"),
          },
        ],
      },
      {
        path: "/onlineClass",
        name: "onlineClass",
        redirect: "/onlineClass/list",
        children: [
          {
            path: "/onlineClass/list",
            name: "onlineClass",
            component: () => import("@/views/onlineClass/index.vue"),
          },
          {
            path: "/onlineClass/details",
            name: "onlineDetails",
            component: () => import("@/views/onlineClass/details.vue"),
          },
        ],
      },
      {
        path: "/excellentCourse",
        name: "excellentCourse",
        redirect: "/excellentCourse/list",
        children: [
          {
            path: "/excellentCourse/list",
            name: "excellentCourse",
            component: () => import("@/views/excellentCourse/index.vue"),
          },
        ],
      },
      {
        path: "/aboutUs",
        name: "aboutUs",
        component: () => import("@/views/aboutUs/index.vue"),
      },
      {
        path: "/school/entrance",
        name: "schoolEntrance",
        component: () => import("@/views/school/entrance.vue"),
      },
      {
        path: "/school",
        name: "school",
        component: () => import("@/views/school/index.vue"),
        redirect: "/school/online",
        children: [
          {
            path: "/school/online",
            name: "schoolOnline",
            component: () => import("@/views/school/online/index.vue"),
          },
          {
            path: "/school/offline",
            name: "schoolOffline",
            component: () => import("@/views/school/offline/index.vue"),
          },
        ],
      },
      {
        path: "/person",
        name: "person",
        component: () => import("@/views/person/index.vue"),
        children: [
          {
            path: "/person/enroll",
            name: "personEnroll",
            component: () => import("@/views/person/enroll/index.vue"),
          },
          {
            path: "/person/history",
            name: "personHistory",
            component: () => import("@/views/person/history/index.vue"),
          },
          {
            path: "/person/collection",
            name: "personCollection",
            component: () => import("@/views/person/collection/index.vue"),
          },
          {
            path: "/person/certificate",
            name: "personCertificate",
            component: () => import("@/views/person/certificate/index.vue"),
          },
          {
            path: "/person/report",
            name: "personReport",
            component: () => import("@/views/person/report/index.vue"),
          },
          {
            path: "/person/class",
            name: "classManage",
            component: () => import("@/views/person/class/index.vue"),
            redirect: "/person/class/time",
            children: [
              {
                path: "/person/class/time",
                name: "timeStatics",
                component: () => import("@/views/person/class/time.vue"),
              },
              {
                path: "/person/class/course",
                name: "courseStatics",
                component: () => import("@/views/person/class/course.vue"),
              },
              {
                path: "/person/class/timeDetails",
                name: "timeStaticsDetails",
                component: () => import("@/views/person/class/timeDetails.vue"),
              },
              {
                path: "/person/class/courseDetails",
                name: "courseStaticsDetails",
                component: () =>
                  import("@/views/person/class/courseDetails.vue"),
              },
            ],
          },
        ],
      },
    ],
  },
];
