<template>
  <div ref="modalRef">
    <a-modal
      v-model:open="open"
      :footer="null"
      :closable="false"
      :getContainer="() => $refs.modalRef"
      @cancel="onCancel"
    >
      <div class="close">
        <img src="@/assets/login/close.png" alt="" @click="onCancel" />
      </div>
      <div class="top">
        <p
          v-for="item in textArr"
          :class="['login_text', { login_text_active: active == item.id }]"
          :key="item.id"
          @click="active = item.id"
        >
          {{ item.text }}
        </p>
      </div>
      <div class="form">
        <a-form
          :model="formState"
          autocomplete="off"
          @finish="onFinish"
          @finishFailed="onFinishFailed"
        >
          <a-form-item
            name="phone"
            :rules="[{ required: true, message: '请输入手机号!' }]"
          >
            <a-input
              placeholder="请输入手机号"
              v-model:value="formState.phone"
              maxlength="11"
            />
          </a-form-item>
          <a-form-item
            v-if="active == 0"
            name="code"
            :rules="[{ required: true, message: '请输入验证码!' }]"
          >
            <a-input
              maxlength="6"
              placeholder="请输入验证码"
              v-model:value="formState.code"
            >
              <template #suffix>
                <span
                  :class="['get_code', { get_code_grey: isSended }]"
                  @click="onClickCode"
                  >{{ !isSended ? "获取验证码" : time + "s" }}</span
                >
              </template>
            </a-input>
          </a-form-item>
          <a-form-item
            v-if="active == 1"
            name="code"
            :rules="[{ required: true, message: '请输入密码!' }]"
          >
            <a-input
              placeholder="请输入密码"
              v-model:value="formState.code"
              type="password"
            >
              <template #suffix>
                <a-tooltip placement="bottomRight">
                  <template #title>
                    <div>
                      <p>忘记密码？</p>
                      <p>请使用短信验证码登录</p>
                    </div>
                  </template>
                  <span class="get_code" ref="codeRef">忘记密码</span>
                </a-tooltip>
              </template>
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit">登录</a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { useCommonStore, useUserStore } from "@/store";
import { ref, reactive, getCurrentInstance, computed } from "vue";

const { proxy } = getCurrentInstance();

const userStore = useUserStore(),
  commonStore = useCommonStore();

const open = computed(() => {
    return commonStore.loginOpen;
  }),
  onOpen = () => {
    commonStore.SET_OPEN(true);
  };

const textArr = reactive([
    { id: 0, text: "验证码登录" },
    { id: 1, text: "密码登录" },
  ]),
  active = ref(0);

const time_init = 60;
const time = ref(time_init),
  formState = reactive({ phone: "", code: "" }),
  isSended = ref(false),
  onClickCode = () => {
    const { phone } = formState;
    if (isSended.value || !phone) return;

    sendSms().then((res) => {
      if (res) {
        isSended.value = true;
        const timer = setInterval(() => {
          time.value = --time.value;
          if (time.value == 0) {
            isSended.value = false;
            time.value = time_init;
            clearInterval(timer);
          }
        }, 1000);
      }
    });
  },
  sendSms = () => {
    return new Promise((resolve, reject) => {
      const { phone } = formState;
      if (phone.length < 11) return;
      proxy.$api.sendSms({ phone }).then((res) => {
        if (res) {
          resolve(true);
        }
      });
    });
  },
  onCancel = () => {
    commonStore.SET_OPEN(false);
  },
  onFinish = (values) => {
    const { phone, password, code } = values;
    const params = { type: active.value, phone, data: password || code };
    userStore.LOGIN(params);
  };

defineExpose({ onOpen });
</script>

<style lang="less" scoped>
:deep(.ant-modal) {
  width: 686px !important;
  height: fit-content !important;
  padding: 0;

  .ant-modal-content {
    width: 100%;
    height: 100%;
    padding: 0;

    .ant-modal-body {
      width: 100%;
      height: 100%;
      padding: 50px 45px 40px 45px;
      box-sizing: border-box;

      .close {
        width: 34.3px;
        height: 34.3px;
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .top {
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;

        .login_text {
          color: #000;
          height: 54px;
          font-family: PingFang SC;
          font-size: 28px;
          cursor: pointer;

          &.login_text_active {
            font-weight: 600;
            position: relative;

            &::after {
              width: 66px;
              height: 10px;
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-33px);
              border-radius: 5px;
              content: "";
              background: #0fb8ab;
            }
          }

          &:last-of-type {
            margin-left: 80px;
          }
        }
      }

      .form {
        width: 100%;
        height: fit-content;
        margin-top: 30px;

        .ant-form-item {
          margin-bottom: 22px;

          &:last-child {
            margin-bottom: 0;
          }

          .ant-input {
            height: 79.59px;
            font-size: 24px;
          }

          .ant-input-affix-wrapper {
            padding: 0 11px;
          }

          .get_code {
            color: #0093ff;
            font-family: PingFang SC;
            font-size: 28px;
            cursor: pointer;

            &.get_code_grey {
              color: #7a7a7a;
            }
          }

          .ant-btn {
            width: 100%;
            height: 60px;
            border-radius: 10px;
            font-size: 28px;
            background: #0fb8ab;
            margin-top: 8px;
          }
        }
      }
    }
  }
}
</style>
