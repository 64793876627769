<template>
  <div class="course_item" @click="toDetail(courseData)">
    <div
      class="live_tag"
      v-if="
        courseData.liveState &&
        ['live', 'playback'].includes(courseData.liveState)
      "
    >
      <span class="live_label">{{ liveMap[courseData.liveState] }}</span>
    </div>
    <img :src="courseData.cover" alt="" class="img" />
    <div class="text_box">
      <p class="title text_overflow_2">{{ courseData.courseName }}</p>
      <div class="text_tips">
        <span
          class="text_cyan"
          :class="courseData.classifyId <= 1 ? 'cyan1' : 'cyan2'"
          >{{ courseData.classifyName }}</span
        >
        <span class="text_cyan cyan3">{{
          identityMap[courseData.careerStage]
        }}</span>
      </div>
      <span class="nums">{{ courseData.studyCount }}人学习过</span>
    </div>
  </div>
</template>

<script setup>
import { reactive, getCurrentInstance, ref, onMounted, computed } from "vue";
const { proxy } = getCurrentInstance();

const props = defineProps({
  courseData: { type: Object, default: {} },
  courseId: { type: Number, default: 0 },
});
import { useCommonStore } from "@/store";
const commonStore = useCommonStore();
const identityMap = computed(() => {
    let map = {};
    commonStore.identityArr.forEach((item) => {
      map[item.id] = item.name;
    });
    return map;
  }),
  liveMap = computed(() => {
    return commonStore.liveMap;
  });
const toDetail = (e) => {
  const { courseType, courseId } = e;
  if (props.courseId == courseId) return;
  switch (courseType) {
    case 1:
      proxy.$router.push({
        name: "onlineDetails",
        query: {
          courseId,
        },
      });
      break;
    case 2:
      proxy.$router.push({
        name: "offlineDetails",
        query: {
          courseId,
        },
      });
      break;
  }
};
</script>

<style lang="less" scoped>
.course_item {
  width: 100%;
  height: 100%;
  display: flex;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  position: relative;

  .live_tag {
    height: 18px;
    background: #eb5e59;
    padding: 0 6px 1px 8px;
    box-sizing: border-box;
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: 500;
    border-radius: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;

    .live_label {
      color: #fff;
    }
  }

  .img {
    margin-right: 12px;
    width: 208px;
    height: 117px;
    border-radius: 10px;
  }

  .text_box {
    flex: 1;
    position: relative;

    .title {
      color: #000;
      font-size: 20px;
      line-height: normal;
    }

    .text_tips {
      display: flex;
      align-items: center;

      .text_cyan {
        // margin: 8px;
        margin-right: 8px;
        padding: 2px 4px;
        border-radius: 5px;
        font-size: 18px;
        line-height: normal;
      }

      .cyan1 {
        background: #ffe2de;
        color: #eb5e59;
      }

      .cyan2 {
        background: #e1f4f9;
        color: #41c3d6;
      }

      .cyan3 {
        background: #fff;
        color: #0fb8ab;
        border: 0.5px solid #cfecea;
      }
    }

    .nums {
      color: #7a7a7a;
      font-size: 16px;
      line-height: normal;
      display: block;
      margin-top: 12px;
      // position: absolute;
      // bottom: 0;
      // left: 0;
    }
  }
}
</style>
