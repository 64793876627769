<template>
  <Header />
  <div class="main" ref="mainRef">
    <router-view />
    <Footer />
  </div>
</template>

<script setup>
import { Header, Footer } from "@/components/index";
import { ref, watch, watchEffect } from "vue";
import { useRoute } from "vue-router";

const router = useRoute();

const mainRef = ref();

watchEffect(() => {
  if (router.name) mainRef.value?.scrollTo(0, 0);
});
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 0;
  flex: 1;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
}
.text_overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text_overflow_2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text_overflow_3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
