/*
 * @Date: 2023-02-14 09:56:14
 * @LastEditTime: 2023-02-15 09:41:12
 */
import BaseLayout from "@/layout/BaseLayout";

export default {
  path: "/user",
  name: "user",
  component: BaseLayout,
  redirect: "/user/login",
  children: [
    {
      path: "login",
      name: "login",
      component: () => import("@/views/user/login"),
    },
  ],
};
