/*
 * @Date: 2022-08-16 09:31:08
 * @LastEditTime: 2023-02-15 11:34:23
 */
import router from "@/router";
import api from "@/api";
import { defineStore } from "pinia";
import useCommonStore from "../common";
import { message } from "ant-design-vue";

const useUserStore = defineStore("user", {
  state: () => ({
    token: "",
    userInfo: {},
    classList: [],
  }),
  getters: {},
  actions: {
    SET_TOKEN(token) {
      this.token = token;
    },
    SET_USER_INFO(data) {
      this.userInfo = data;
    },
    getClassList(role) {
      api.getClassList({ role }).then((res) => {
        if (res) this.classList = res.data;
      });
    },
    LOGIN(params) {
      const { type, phone, data } = params;
      console.log(type, phone, data);
      if (!phone || !data) return;
      if (type == 0) this.codeLogin(phone, data);
      else this.passwordLogin(phone, data);
    },
    codeLogin(phone, code) {
      api.codeLogin({ phone, code }).then((res) => {
        if (res) this.actionResult(res);
      });
    },
    passwordLogin(phone, password) {
      api.passwordLogin({ phone, password }).then((res) => {
        if (res) this.actionResult(res);
      });
    },
    actionResult(res) {
      const { data } = res;
      const { token } = data;

      this.token = token;
      this.userInfo = data;

      localStorage.setItem("token", token);
      localStorage.setItem("userInfo", JSON.stringify(data));

      this.getClassList(data.role);

      message.success("登录成功", 2);
      const commonStore = useCommonStore();
      commonStore.SET_OPEN(false);
    },
    logout(name) {
      this.token = "";
      this.userInfo = "";

      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");

      let arr = [];
      const commonStore = useCommonStore();
      commonStore.personNav.forEach((nav) => {
        arr.push(nav.pathName);
      });
      arr = [...arr, "schoolEntrance", "schoolOnline", "schoolOffline"];
      if (arr.includes(name)) {
        router.push({ path: "/" });
        this.classList = [];
      }
      message.success("退出成功", 2);
    },
  },
});

export default useUserStore;
