/*
 * @Date: 2023-02-14 09:56:13
 * @LastEditTime: 2024-04-22 14:21:07
 */
import { request } from "@/request/request";
import { parameterChange } from "@/utils/utils";

export default {
  // 获取线下培训/实操课列表
  getCoursePageList(data) {
    return request({
      url: "/offline_course/getCoursePageList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 获取课程详情
  getCourseDetail(data) {
    return request({
      url: "/offline_course/getCourseDetail?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 获取报名状态
  getEnrollCheck(data) {
    return request({
      url: "/offline_course/getEnrollCheck?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 获取报名表单
  getEnrollForm(data) {
    return request({
      url: "/offline_course/getEnrollForm?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 课程/活动报名
  enroll(data) {
    return request({
      url: "/offline_course/enroll",
      method: "post",
      data,
    });
  },
  // 获取直播间链接
  getLiveUrl(data) {
    return request({
      url: "/offline_course/getLiveUrl?" + parameterChange(data),
      method: "get",
      data,
    });
  },
};
