/*
 * @Date: 2022-07-11 14:27:16
 * @LastEditTime: 2023-03-02 09:45:42
 */
import {
  Button,
  Form,
  Input,
  InputSearch,
  Modal,
  Tooltip,
  Dropdown,
  Menu,
  MenuItem,
  MenuDivider,
  Popover,
  Carousel,
  Pagination,
  message,
  Empty,
  Tabs,
  TabPane,
  Space,
  RadioGroup,
  Radio,
  Select,
  Table,
} from "ant-design-vue";

import "ant-design-vue/dist/reset.css";

export function lazyUse(app) {
  app.use(Button);
  app.use(InputSearch);
  app.use(Input);
  app.use(Modal);
  app.use(Form);
  app.use(Tooltip);
  app.use(Dropdown);
  app.use(Menu);
  app.use(MenuItem);
  app.use(MenuDivider);
  app.use(Popover);
  app.use(Carousel);
  app.use(Pagination);
  app.use(Empty);
  app.use(Tabs);
  app.use(TabPane);
  app.use(Space);
  app.use(RadioGroup);
  app.use(Radio);
  app.use(Select);
  app.use(Table);

  app.config.globalProperties.$message = message;
}
