import setupPermissionGuard from "./permission";

function setupPageGuard(router) {
  router.beforeEach(() => {});
  router.afterEach(() => {});
}

export default function createRouteGuard(router) {
  setupPageGuard(router);
  setupPermissionGuard(router);
}
