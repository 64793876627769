<!--
 * @Date: 2023-02-14 09:56:13
 * @LastEditTime: 2023-02-15 10:22:39
-->
<template>
  <router-view v-if="isRouterActive" />
</template>

<script setup>
import { ref, provide, nextTick } from "vue";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
const isRouterActive = ref(true);
provide("reload", () => {
  isRouterActive.value = false;
  nextTick(() => {
    isRouterActive.value = true;
  });
});

dayjs.locale("zh-cn");
</script>
