/*
 * @Date: 2022-08-16 09:31:08
 * @LastEditTime: 2024-04-23 16:36:11
 */
import { defineStore } from "pinia";

const useCommonStore = defineStore("common", {
  state: () => ({
    loginOpen: false,
    schoolNav: [
      "schoolEntrance",
      "schoolOnline",
      "schoolOffline",
      "schoolDetails",
    ],
    personNav: [
      {
        icon: require("@/assets/person/enroll.png"),
        iconActive: require("@/assets/person/enroll_active.png"),
        label: "报名管理",
        pathName: "personEnroll",
      },
      {
        icon: require("@/assets/person/history.png"),
        iconActive: require("@/assets/person/history_active.png"),
        label: "历史记录",
        pathName: "personHistory",
      },
      {
        icon: require("@/assets/person/collection.png"),
        iconActive: require("@/assets/person/collection_active.png"),
        label: "我的收藏",
        pathName: "personCollection",
      },
      {
        icon: require("@/assets/person/certificate.png"),
        iconActive: require("@/assets/person/enroll.png"),
        label: "我的证书",
        pathName: "personCertificate",
      },
      {
        icon: require("@/assets/person/report.png"),
        iconActive: require("@/assets/person/report_active.png"),
        label: "学习报告",
        pathName: "personReport",
      },
    ],
    teacherNav: [
      {
        icon: require("@/assets/person/enroll.png"),
        iconActive: require("@/assets/person/enroll_active.png"),
        label: "报名管理",
        pathName: "personEnroll",
      },
      {
        icon: require("@/assets/person/history.png"),
        iconActive: require("@/assets/person/history_active.png"),
        label: "历史记录",
        pathName: "personHistory",
      },
      {
        icon: require("@/assets/person/collection.png"),
        iconActive: require("@/assets/person/collection_active.png"),
        label: "我的收藏",
        pathName: "personCollection",
      },
      {
        icon: require("@/assets/person/certificate.png"),
        iconActive: require("@/assets/person/enroll.png"),
        label: "我的证书",
        pathName: "personCertificate",
      },
      {
        icon: require("@/assets/person/report.png"),
        iconActive: require("@/assets/person/report_active.png"),
        label: "学习报告",
        pathName: "personReport",
      },
      {
        icon: require("@/assets/person/class.png"),
        iconActive: require("@/assets/person/class_active.png"),
        label: "班级管理",
        pathName: "classManage",
      },
    ],
    identityArr: [
      { id: 1, name: "在校生" },
      { id: 2, name: "助理医生" },
      { id: 3, name: "执业医生" },
    ],
    liveMap: {
      unStart: "未开始",
      live: "直播中",
      end: "已结束",
      waiting: "等待中",
      playback: "看回放",
    },
    defaultAvatar: require("@/assets/avatar.png"),
  }),

  getters: {},
  actions: {
    SET_OPEN(data) {
      this.loginOpen = data;
    },
  },
});

export default useCommonStore;
