/*
 * @Date: 2023-02-14 09:56:13
 * @LastEditTime: 2023-02-14 10:34:10
 */
import { request } from "@/request/request";

export default {
  // 发送短信验证码
  sendSms(data) {
    return request({
      url: "/auth/sendSms",
      method: "post",
      data,
    });
  },
  // 验证码注册/登录
  codeLogin(data) {
    return request({
      url: "/auth/codeLogin",
      method: "post",
      data,
    });
  },
  // 密码登录
  passwordLogin(data) {
    return request({
      url: "/auth/passwordLogin",
      method: "post",
      data,
    });
  },
};
