import { request } from "@/request/request";
import { parameterChange } from "@/utils/utils";

export default {
  // 活动报名列表
  getEnrollList(data) {
    return request({
      url: "/enroll/getEnrollList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 收藏列表
  getCollectList(data) {
    return request({
      url: "/course_collect/getCollectList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 播放记录
  getHistoryList(data) {
    return request({
      url: "/play_history/getHistoryList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 学习报告
  getStudyReport(data) {
    return request({
      url: "/study_record/getStudyReport?" + parameterChange(data),
      method: "get",
      data,
    });
  },
};
