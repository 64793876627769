<template>
  <div class="header">
    <div class="logo_box" @click="$router.push('/')">
      <p class="text">8020平台</p>
      <img class="logo" src="@/assets/logo.png" alt="logo" />
    </div>

    <a-input-search
      class="search_button"
      v-model:value="searchStore.keyword"
      placeholder="搜索课程"
      enter-button="搜索"
      size="large"
      @search="toSearch(searchStore.keyword)"
      @change="fieldChange"
      @blur="inputSearch"
    />
    <div class="searchList" v-if="isSearch && searchList.length">
      <div
        class="item"
        @click="toSearch(item.keyword)"
        v-for="item in searchList"
        :key="item.courseId"
      >
        <p v-html="item.nodes"></p>
      </div>
    </div>
    <div class="group">
      <p class="mini" ref="popoverRef">
        <a-popover placement="bottom">
          <template #content>
            <img class="img" src="@/assets/header/code.png" alt="" />
            <p class="code">微信扫码进入8020平台小程序</p>
          </template>
          小程序
        </a-popover>
      </p>
      <p class="divider">|</p>
      <p
        :class="['school', { school_active: isSchool }]"
        @click="go2page('schoolEntrance', true)"
      >
        专属课程
      </p>
      <p class="divider">|</p>
      <p
        :class="['study', { study_active: isPerson }]"
        @click="go2page('person')"
      >
        我的学习
      </p>
      <p class="divider">|</p>
      <p class="login" @click="login" v-if="!userInfo">登录/注册</p>
      <a-dropdown v-if="userInfo">
        <div class="user_info">
          <a class="ant-dropdown-link" @click.prevent>
            <img
              :src="userInfo?.avatar || commonStore.defaultAvatar"
              class="avatar"
            />
            <CaretDownOutlined />
          </a>
        </div>
        <template #overlay>
          <a-menu>
            <a-menu-item
              v-for="item in personNav"
              :key="item"
              @click="$router.push({ name: item.pathName })"
            >
              <span>{{ item.label }}</span>
            </a-menu-item>
            <a-menu-divider />
            <a-menu-item @click="logout">
              <a href="javascript:;">退出</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <div v-else class="avatar"></div>
    </div>
  </div>
  <Navbar />

  <Login ref="loginRef" />
</template>

<script setup>
import { ref, getCurrentInstance, computed, watch, onMounted } from "vue";
import { set, get } from "@vueuse/core";
import { Login, Navbar } from "@/components";
import { useCommonStore, useUserStore, useSearchStore } from "@/store";
import { CaretDownOutlined } from "@ant-design/icons-vue";
import { useRoute } from "vue-router";

const { proxy } = getCurrentInstance(),
  router = useRoute();

onMounted(() => {
  getClassList();
});

const commonStore = useCommonStore(),
  userStore = useUserStore(),
  searchStore = useSearchStore();

const searchValue = ref(""),
  searchList = ref([]),
  isSearch = ref(false),
  // 监听输入框变化
  fieldChange = () => {
    debounce(searchCompletion, 100);
  },
  inputSearch = () => {
    setTimeout(() => {
      set(isSearch, false);
    }, 100);
  },
  // 搜索补全
  searchCompletion = async () => {
    const keyword = searchStore.keyword;
    if (!keyword) return set(isSearch, false);
    const params = {
      keyword,
      page: 1,
      limit: 10,
    };
    const res = await proxy.$api.search(params);
    if (res) {
      const arr = res.data.list.map((item) => {
        const regExp = new RegExp(keyword, "g");
        let text = item.courseName.replace(
          regExp,
          `<p style="color: #0FB8AB;display: inline-block;">${keyword}</p>`
        );
        return {
          nodes: `<p style="display: inline-block" class="text">${text}</p>`,
          keyword: item.courseName,
        };
      });
      set(searchList, arr);
      set(isSearch, true);
    }
  },
  // 前往搜索页
  toSearch = (key) => {
    const keyword = searchStore.keyword;
    if (!keyword) return;
    set(isSearch, false);
    searchStore.SET_KEYWORD(key);
    searchStore.SET_SEARCH_KEYWORD(key);
    // const path = proxy.$router.currentRoute._value.name;
    proxy.$router.push({
      name: "search",
      // query: {
      //   keyword: key,
      // },
    });
  };

let timer = null;
const debounce = (fn, interval = 1000) => {
  if (timer == null) {
    timer = setTimeout(() => {
      fn();
      clearTimeout(timer);
      timer = null;
    }, interval);
  }
};

const login = () => {
    commonStore.SET_OPEN(true);
  },
  logout = () => {
    userStore.logout(router.name);
  },
  userInfo = computed(() => {
    return userStore.userInfo;
  });

const personNav = computed(() => {
    if (userInfo.value && userInfo.value.role == 1)
      return commonStore.teacherNav;
    else return commonStore.personNav;
  }),
  go2page = (name, flag = false) => {
    if (userInfo.value) proxy.$router.push({ name });
    else return login();

    if (flag && userStore.classList.length == 0)
      proxy.$message.warning({ content: "您还没有关联团队" });
  };

const isPerson = computed(() => {
    let arr = [];
    personNav.value.forEach((nav) => {
      arr.push(nav.pathName);
    });
    arr = [
      ...arr,
      "timeStatics",
      "courseStatics",
      "timeStaticsDetails",
      "courseStaticsDetails",
    ];
    return arr.includes(router.name);
  }),
  isSchool = computed(() => {
    const arr = [];
    commonStore.schoolNav.forEach((nav) => {
      arr.push(nav);
    });
    return arr.includes(router.name);
  }),
  getClassList = () => {
    if (userInfo.value) userStore.getClassList(userInfo.value.role);
  };

watch(
  () => searchStore.searchKeyword,
  (newValue, oldValue) => {
    if (newValue) set(isSearch, false);
  },
  { immediate: true, deep: true }
);
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: fit-content;
  padding: 25px 49px 25px 51px;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  .logo_box {
    width: fit-content;
    height: 64px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .text {
      color: #0fb8ab;
      font-size: 32px;
    }

    .logo {
      width: 53px;
      height: 53px;
      margin-left: 20px;
    }
  }

  .ant-input-search {
    width: 660px;
    height: 60px;
    font-size: 24px;
    line-height: 56px;
    border-radius: 5px;
    border-color: #7a7a7a;
    background: #fff;
    margin-left: 60px;
    color: #fff;
    font-family: PingFang SC;
    display: flex;
    align-items: center;

    :deep(.ant-input) {
      height: 60px;
      border-color: #7a7a7a;
      box-shadow: unset;
    }
  }

  :deep(.ant-btn) {
    width: 96.27px;
    height: 60px;
    background: #eb5e59;
  }

  .group {
    flex: 1;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #000;
    font-family: PingFang SC;
    font-size: 20px;

    & > *:not(.divider, .avatar) {
      cursor: pointer;
    }

    .divider {
      padding: 0 24px;
    }

    .avatar {
      width: 60px;
      height: 60px;
      background: #d9d9d9;
      border-radius: 50%;
      margin-left: 16px;
    }

    .user_info {
      width: 110px;
      height: 64px;
    }

    .study_active,
    .school_active {
      color: #0fb8ab;
    }
  }
}

.searchList {
  padding: 11px 16px;
  position: absolute;
  top: 86px;
  left: 325px;
  width: 465px;
  border-radius: 0px 0px 5px 5px;
  border: 0.5px solid #7a7a7a;
  background: #fff;
  z-index: 999;

  .item {
    margin-bottom: 12px;
    color: #000;
    font-size: 24px;
    line-height: normal;
    cursor: pointer;
  }
}
</style>
