<template>
  <div class="list_item" @click="go2page(item)">
    <div
      class="live_tag"
      v-if="item.liveState && ['live', 'playback'].includes(item.liveState)"
    >
      <span class="live_label">{{ liveMap[item.liveState] }}</span>
    </div>
    <img :src="item.cover" alt="" class="cover" />
    <div class="bottom_info">
      <div class="first_row text_overflow">
        {{ item.courseName ?? "课程不存在" }}
      </div>
      <div class="second_row">
        <span class="col"
          >会议时间：{{ formatTime(item.startTime) }}-{{
            formatTime(item.endTime)
          }}</span
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCommonStore } from "@/store";
import { reactive, ref, getCurrentInstance, computed } from "vue";

const { proxy } = getCurrentInstance(),
  commonStore = useCommonStore();

const props = defineProps({ item: Object });

const liveMap = computed(() => {
  return commonStore.liveMap;
});

const go2page = ({ courseId }) => {
  proxy.$router.push({
    name: "offlineDetails",
    query: { courseId },
  });
};

const formatTime = (time) => {
  if (time) {
    const timeArr = time.split("-"),
      len = timeArr.length;
    let format;

    if (len == 3)
      format = timeArr[0] + "年" + timeArr[1] + "月" + timeArr[2] + "日";
    else if (len == 2) format = timeArr[0] + "月" + timeArr[1] + "日";
    else format = timeArr[0] + "日";
    return format;
  }
};
</script>

<style lang="less" scoped>
.list_item {
  width: 100%;
  height: 332px;
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  cursor: pointer;
  position: relative;

  .live_tag {
    height: 18px;
    background: #eb5e59;
    padding: 0 6px 1px 8px;
    box-sizing: border-box;
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: 500;
    border-radius: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;

    .live_label {
      color: #fff;
    }
  }

  .cover {
    width: 100%;
    height: 236.595px;
    border-radius: 10px 10px 0 0;
  }

  .bottom_info {
    width: 100%;
    height: 0;
    flex: 1;
    padding: 14.41px 29.65px 14px 12px;
    box-sizing: border-box;
    position: relative;

    .first_row {
      width: 100%;
      height: fit-content;
      font-family: PingFang SC;
      font-size: 24px;
      font-weight: 500;
      line-height: 34px;
      font-family: "regular";
    }

    .second_row {
      width: 100%;
      height: fit-content;
      display: flex;
      margin-top: 8px;

      .col {
        width: fit-content;
        height: fit-content;
        color: #000;
        font-family: PingFang SC;
        font-size: 18px;
      }
    }
  }
}
</style>
