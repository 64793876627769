<template>
  <div class="footer">
    <div class="item" v-for="(item, index) in items" :key="item.id">
      <p>{{ item.label }}</p>
      <p class="divider" v-if="index != items.length - 1">|</p>
    </div>
    <span class="company">上海慧芽之路互联网科技有限公司</span>
    <a class="icp" href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2023036472号-1</a>
  </div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance } from "vue";

const { proxy } = getCurrentInstance();

const items = reactive([
  { id: 1, label: "关于8020平台" },
  { id: 2, label: "版权说明" },
  { id: 3, label: "隐私政策" },
  { id: 4, label: "用户协议" },
  { id: 5, label: "联系我们" },
]);
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  height: 160px;
  padding: 24.46px 0 110.54px 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: #e8e8e8;
  position: relative;

  .item {
    color: #7a7a7a;
    font-family: PingFang SC;
    font-size: 18px;
    display: flex;
    align-items: center;

    & > *:not(.divider) {
      cursor: pointer;
    }

    .divider {
      padding: 0 24px;
    }
  }

  .icp {
    color: #7a7a7a;
    font-family: PingFang SC;
    font-size: 18px;
    position: absolute;
    bottom: 45.54px;
    left: 360px;
  }
  .company {
    color: #7a7a7a;
    font-family: PingFang SC;
    font-size: 18px;
    position: absolute;
    bottom: 45.54px;
    left: 50px;
  }
}
</style>
