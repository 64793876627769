<template>
  <div class="navbar" v-if="isShow">
    <div
      class="item"
      :class="nowPath == item.path ? 'active' : ''"
      v-for="item in navbar"
      :key="item.name"
      @click="toPath(item.path)"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script setup>
import { reactive, getCurrentInstance, ref, onMounted, watch } from "vue";
import { set, get } from "@vueuse/core";
import { useRoute } from "vue-router";

const { proxy } = getCurrentInstance(),
  router = useRoute();

const isShow = ref(false),
  nowPath = ref(""),
  navArr = [
    "home",
    "onlineClass",
    "offlineClass",
    "excellentCourse",
    "personEnroll",
    "personHistory",
    "personCollection",
    "personCertificate",
    "personReport",
    "schoolEntrance",
    "schoolOnline",
    "schoolOffline",
    "schoolDetails",
    "classManage",
    "timeStatics",
    "courseStatics",
    "timeStaticsDetails",
    "courseStaticsDetails",
  ];
watch(
  () => router,
  (newValue, oldValue) => {
    const value = newValue.name;
    if (navArr.includes(value)) {
      set(isShow, true);
      set(nowPath, value);
    } else {
      set(isShow, false);
    }
  },
  { immediate: true, deep: true }
);
onMounted(() => {});

const toPath = async (name) => {
  if (!name) return proxy.$message.info({ content: "该功能暂未开放" });
  proxy.$router.push({ name });
};
const navbar = ref([
  { name: "首页", path: "home" },
  { name: "线上课程", path: "onlineClass" },
  { name: "线下课程", path: "offlineClass" },
  { name: "精品课", path: "excellentCourse" },
  // { name: "关于我们", path: "aboutUs" },
]);
</script>

<style lang="less" scoped>
.navbar {
  display: flex;
  background: #0fb8ab;

  .item {
    display: flex;
    height: 48px;
    padding: 16px 60px;
    justify-content: center;
    align-items: center;
    background: unset;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    font-family: "regular";
    line-height: normal;
    cursor: pointer;

    &:hover {
      background: #cfecea;
      color: #0fb8ab;
    }
  }

  .active {
    background: #cfecea;
    color: #0fb8ab;
  }
}
</style>
