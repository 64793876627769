/*
 * @Date: 2023-02-14 09:56:13
 * @LastEditTime: 2023-02-14 10:34:10
 */
import { request } from "@/request/request";
import { parameterChange } from "@/utils/utils";

export default {
  // 获取课程详情
  getOnlineCourseDetail (data) {
    return request({
      url: "/online_course/getCourseDetail?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 获取线上课列表
  getOnlineCourseList (data) {
    return request({
      url: "/online_course/getCourseList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 获取课程评价
  getOnlineCourseEvaluate (data) {
    return request({
      url: "/online_course/getCourseEvaluate?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 课程评价点赞/取消点赞
  evaluateLike (data) {
    return request({
      url: "/evaluate/evaluateLike",
      method: "post",
      data,
    });
  },
  // 收藏课程/取消收藏
  courseCollect (data) {
    return request({
      url: "/course_collect/courseCollect",
      method: "post",
      data,
    });
  },
  // 评价课程
  courseEvaluate (data) {
    return request({
      url: "/evaluate/courseEvaluate",
      method: "post",
      data,
    });
  },
  // 获取评价详情
  getEvaluateDetail (data) {
    return request({
      url: "/online_course/getEvaluateDetail?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 播放记录
  addPlayHistory (data) {
    return request({
      url: "/online_course/addPlayHistory",
      method: "post",
      data,
    });
  },
  // 学习记录
  addStudyRecord (data) {
    return request({
      url: "/online_course/addStudyRecord",
      method: "post",
      data,
    });
  },
  // 获取连接ID(ws)
  getConnectId (data) {
    return request({
      url: "/ws/getConnectId",
      method: "get",
      data,
    });
  },
  // 发送弹幕(ws)
  wsSendBarrage (data) {
    return request({
      url: "/ws/sendBarrage?type=" + data.type,
      method: "post",
      data,
    });
  },
};
