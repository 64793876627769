import { useUserStore } from "@/store/index";
import { message } from "ant-design-vue";

const whiteList = [
  "home",
  "search",
  "onlineClass",
  "onlineDetails",
  "offlineClass",
  "offlineDetails",
  "excellentCourse",
];

export default function setupPermissionGuard(router) {
  router.beforeEach((to, from, next) => {
    const counter = useUserStore();
    if (whiteList.includes(to.name)) next();
    else if (counter.token) next();
    else message.warning({ content: "请登录账户" });
  });
}
