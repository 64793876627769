import { defineStore } from "pinia";

const useSearchStore = defineStore("search", {
  state: () => ({
    keyword: "",
    searchKeyword: "",
  }),

  getters: {},
  actions: {
    SET_KEYWORD(data) {
      this.keyword = data;
    },
    SET_SEARCH_KEYWORD(data) {
      this.searchKeyword = data;
    },
  },
});

export default useSearchStore;
