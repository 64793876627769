import { request } from "@/request/request";
import { parameterChange } from "@/utils/utils";

export default {
  // 获取用户班级列表
  getClassList(data) {
    return request({
      url: "/team/getClassList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 获取团队统计信息
  getTeamStatistics(data) {
    return request({
      url: "/team/getTeamStatistics?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 获取学习统计信息
  getStudyStatistics(data) {
    return request({
      url: "/team/getStudyStatistics?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 获取学生统计信息
  getUserStatistics(data) {
    return request({
      url: "/team/getUserStatistics?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 获取课程统计信息
  getCourseStatistics(data) {
    return request({
      url: "/team/getCourseStatistics?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 获取班级统计信息
  getClassStatistics(data) {
    return request({
      url: "/team/getClassStatistics?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 获取课程班级统计信息
  getCourseClassStatistics(data) {
    return request({
      url: "/team/getCourseClassStatistics?" + parameterChange(data),
      method: "get",
      data,
    });
  },
};
